<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right;">

			</div>
			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>保险配置</el-breadcrumb-item>
					<el-breadcrumb-item>保险二维码</el-breadcrumb-item>
				</el-breadcrumb>
			</div>

		</div>
		<div class="bcontent">


			<el-row type="flex" justify="start">
				<el-col :span="24">
					<el-tabs v-model="activeName" type="border-card">
						<el-tab-pane label="字典列表" name="dictab">
							<el-table :data="tableData" style="width: 100%;" border stripe>
								<el-table-column type="index" label="序号" width="60px"></el-table-column>
								<el-table-column prop="title" label="字典名称" min-width="150">

								</el-table-column>
								<el-table-column prop="path" label="价格" width="150"></el-table-column>
								<el-table-column prop="path" label="路径" width="350">
									<template slot-scope="scope">

										pages/modules/toubao/toubao?price={{ scope.row.path }}

									</template>
								</el-table-column>
								<el-table-column prop="url" label="小程序码">
									<template slot-scope="scope">

										<el-image :src="scope.row.url" style="width:100px;height:100px;" :preview-src-list="[scope.row.url]"></el-image>

									</template>

								</el-table-column>

								<el-table-column label="操作" width="120">
									<template slot-scope="scope">

										<el-button @click="handleModify(scope.row)" type="text" size="small">修改
										</el-button>

									</template>
								</el-table-column>
							</el-table>

						</el-tab-pane>
						<el-tab-pane disabled>
							<div slot="label">
								<el-button size="small" icon="el-icon-plus" @click="formAdd" type="primary">新增
								</el-button>

								<!-- <el-button size="small" icon="el-icon-refresh" @click="copyOther" type="primary">同步
								</el-button> -->
							</div>
						</el-tab-pane>
					</el-tabs>
					<div>




					</div>

				</el-col>





			</el-row>
			<el-dialog :title="modalTitle" :visible.sync="isShowModal" :close-on-click-modal="false"
				:destroy-on-close="true">
				<el-form :model="formData" ref="ruleForm" label-width="120px"
					style="max-height: 450px;overflow-y: auto;min-height: 300px;">
					<el-form-item label="二维码名称：">
						<el-input v-model.trim="formData.title" style="width: 80%" />
					</el-form-item>
					<el-form-item label="价格：">
						<el-input v-model.trim="formData.path" type="number" style="width: 150px" />
					</el-form-item>
				</el-form>
				<el-row>
					<el-col :span="22" style="text-align: right;">
						<el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
						<el-button size="small" type="primary" @click="submitForm('ruleForm')">保存</el-button>
					</el-col>
				</el-row>
			</el-dialog>




		</div>
	</div>
</template>
<script>
import api from '@/api/api';

export default {
	name: 'deptRole',
	data() {
		return {
			isShowModal: false,
			modalTitle: '添加',
			formData: {
				title: "",
				path: "",
				url: "",
			},
			//table

			tableData: [],
			activeName: "dictab"


		}
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {

			this.$http.post("/api/qrcode_list",).then(res => {
				this.tableData = res.data


			})
		},

		formAdd() {
			this.isShowModal = true;
			this.formData = {
				title: "",
				path: "",
				url: "",
			}
			this.modalTitle = '添加'
		},

		handleModify(row) {
			this.isShowModal = true;
			this.modalTitle = '修改';


			this.formData = {
				...row
			}
		},
		handleDel(row) {
			this.$http.post('/api/qrcode_delete', {
				id: row.id
			}).then(res => {
				this.$message.success('删除成功')
				this.getList();
			})
		},
		handleCopy(row) {
			this.isShowModal = true;
			this.modalTitle = '新增';
			delete row.id
			this.formData = {
				...row
			}
		},

		createQrcode() {



		},

		submitForm(formName) {


			let params = {
				...this.formData
			}
			params.del_flag = 0;
			if (params.path) {
				let jsonobj = {
					path: "pages/modules/toubao/toubao?price=" + this.formData.path
				}
				params.jsonobj = jsonobj

				this.$http.post("/api/create_qrcode", params).then(res => {
					if (res.data) {
						params.url = res.data.url
						this.$http.post("/api/qrcode_edit", params).then(res => {
							this.$message.success('保存成功')
							this.getList();
							this.isShowModal = false;
						})
					}
				})





			} else {
				this.$message.error('请输入价格')
			}




		},

	}
}
</script>
